var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MmpIntegrationGuide", {
        attrs: { mmpIntegrationData: _vm.mmpIntegrationData },
        on: {
          closed: function ($event) {
            return _vm.mmpIntegrationGuideClosed()
          },
        },
      }),
      _c("p", { staticClass: "medium-font font-25 color-primary" }, [
        _vm._v("Integrate your MMPs"),
      ]),
      _c(
        "v-form",
        {
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [
          _c("v-select", {
            attrs: {
              rules: _vm.mmpFormService.defaultRules,
              items: _vm.mmpFormService.mmpList,
              "item-value": "id",
              "item-text": "id",
              label: "Select MMP",
              height: "56",
              "hide-details": "auto",
              required: "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (ref) {
                  var item = ref.item
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-list-item",
                      _vm._g(
                        _vm._b(
                          { attrs: { dense: "" } },
                          "v-list-item",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c(
                          "v-list-item-avatar",
                          [_c("v-img", { attrs: { src: item.icon } })],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v(_vm._s(item.id))])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.mmpFormService.mmpId,
              callback: function ($$v) {
                _vm.$set(_vm.mmpFormService, "mmpId", $$v)
              },
              expression: "mmpFormService.mmpId",
            },
          }),
          _c("v-select", {
            attrs: {
              rules: _vm.mmpFormService.defaultRules,
              items: _vm.appsList,
              "item-text": "content.title",
              "return-object": "",
              label: "Choose an app",
              height: "56",
              "hide-details": "auto",
              required: "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    [
                      _c("v-img", {
                        staticClass: "rounded-lg",
                        attrs: {
                          color: "secondary",
                          "max-width": "30",
                          "max-height": "30",
                          "min-width": "20",
                          "min-height": "20",
                          src: item.content.icon,
                        },
                      }),
                      _c(
                        "v-list-item-content",
                        { staticClass: "ml-2" },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(item.content.title)),
                          ]),
                        ],
                        1
                      ),
                    ],
                  ]
                },
              },
            ]),
            model: {
              value: _vm.mmpFormService.app,
              callback: function ($$v) {
                _vm.$set(_vm.mmpFormService, "app", $$v)
              },
              expression: "mmpFormService.app",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex mt-10" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-start" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "overview", query: { tour: "true" } } } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2 color-primary",
                    attrs: { outlined: "" },
                  },
                  [_vm._v(" Skip ")]
                ),
              ],
              1
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://support.searchadsmaven.com/",
                  target: "_blank",
                },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2 color-primary",
                    attrs: { outlined: "" },
                  },
                  [_vm._v(" Start Application Guide ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-end flex-grow-1" },
          [
            _c(
              "v-btn",
              {
                attrs: { disabled: !_vm.formValid, color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.integrateMmp()
                  },
                },
              },
              [_vm._v(" Integrate ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }