

























































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import MmpFormService from './services/mmp-from.service';
import MmpIntegrationGuide from './MmpIntegrationGuide.vue/MmpIntegrationGuide.vue';
import MmpIntegrationGuide from './MmpIntegrationGuide.vue/MmpIntegrationGuide.vue';
import { MMP } from 'src/utils/enums';
import { GENERATE_ATTR_API_KEY } from 'src/utils/apollo-mutations';
import { updateCLF } from 'src/utils/util';
import { CheckListFields } from '@shared/enums';

export default Vue.extend({
    name: 'MmpStep',
    components: {
        MmpIntegrationGuide
    },
    data() {
        return {
            mmpFormService: null,
            formValid: false,
            appsList: [],
            mmpIntegrationData: {
                app: {
                    content: {}
                },
                appEvents: []
            },
            MMP
        };
    },
    props: {

    },
    methods: {
        mmpIntegrationGuideClosed() {
            this.$router.push({ name: 'overview', query: { tour: "true" } });
        },
        setupAppsListListner() {
            this.$root.$on('setup:integrationstep:appslist', (data) => this.appsList = data.appsList);
        },
        async integrateMmp() {
            this.fullScreenLoader = this.$loading.show();
            try {
                const mmpIntegrationResponse = await this.$apollo
                    .mutate({
                        mutation: GENERATE_ATTR_API_KEY,
                        variables: {
                            attrReq: {
                                appName: this.mmpFormService.app.content.title,
                                appId: this.mmpFormService.app.content.id,
                                mmpId: this.mmpFormService.mmpId,
                                mmpName: this._getMMPNameFromId(this.mmpFormService.mmpId)
                            }
                        }
                    })

                this._handleMmpIntegrationResponse(mmpIntegrationResponse);
            } catch (error) {
                this.$notify.error('Failed to integrate MMP.');
                console.error(error);
            }

            this.fullScreenLoader.hide();
        },
        _handleMmpIntegrationResponse(response) {
            if (response.data.generateAttrAPIKey.code != 0) throw response;

            const data = JSON.parse(response.data.generateAttrAPIKey.data);

            // update checklist item
            if (data?.appEvents.length > 0) {
                updateCLF(CheckListFields['mmpIntegration'], this)
            }

            // show integration guide dialog
            this.mmpIntegrationData = {
                integrationGuideDialog: true,
                apiKey: data.apiKey,
                app: this.mmpFormService.app,
                mmpId: this._getSupportedMMPId(this.mmpFormService.mmpId),
                appEvents: data.appEvents
            }
        },
        _getSupportedMMPId(mmpId) {
            return [MMP.KOCHAVA_ID, MMP.BRANCH_ID].includes(mmpId) ? mmpId : MMP.CUSTOM_ID;
        },
        _getMMPNameFromId(mmpId) {
            switch (mmpId) {
                case MMP.KOCHAVA_ID:
                    return MMP.KOCHAVA_NAME;
                case MMP.BRANCH_ID:
                    return MMP.BRANCH_NAME;
                case MMP.CUSTOM_ID:
                    return MMP.CUSTOM_NAME;
                case MMP.ADJUST_ID:
                    return MMP.ADJUST_NAME;
                case MMP.APPSFLYER_ID:
                    return MMP.APPSFLYER_NAME;
                case MMP.SINGULAR_ID:
                    return MMP.SINGULAR_NAME;
            }
        }
    },
    created() {
        this.mmpFormService = new MmpFormService();
        this.setupAppsListListner();
    },
});
