import { MMP } from "src/utils/enums";

interface IMmpFormData {
    mmpId: string;
    app: any;
}

class MmpFormService {
    mmpIntegrationData: IMmpFormData;
    defaultRules = [
        (v) => !!v || 'This field is required'
    ];

    mmpList = [
        { id: MMP.KOCHAVA_ID, icon: require('@assets/images/kochava.png') },
        { id: MMP.BRANCH_ID, icon: require('@assets/images/branch_logo.png') },
        { id: MMP.ADJUST_ID, icon: require('@assets/images/adjust.png') },
        { id: MMP.APPSFLYER_ID, icon: require('@assets/images/appsflyer.png') },
        { id: MMP.SINGULAR_ID, icon: require('@assets/images/singular.png') },
        { id: MMP.CUSTOM_ID, icon: require('@assets/images/custom.png') },
    ]

    constructor() {
        this.resetForm();
    }

    public resetForm() {
        this.mmpIntegrationData = {
            mmpId: '',
            app: null
        }
    }
}

export default MmpFormService;
