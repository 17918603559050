var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.integrated
      ? _c(
          "div",
          [
            _c("p", { staticClass: "medium-font font-25 color-primary" }, [
              _vm._v("Create Integration"),
            ]),
            _c("p", { staticClass: "medium-font font-16 color-primary" }, [
              _vm._v(
                "Enter your Apple ID to create the bi-directional sync between Search Ads Maven and Apple Search Ads. If you have an MMP partner, those integrations can be set up here as well. Let's begin by importing your Apple Search Ads data."
              ),
            ]),
            _c(
              "v-btn",
              {
                staticClass: "mt-4",
                attrs: { color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.startAsaIntegration()
                  },
                },
              },
              [_c("strong", [_vm._v("Enter your apple ID")])]
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("IncorrectPermissions", {
              attrs: {
                permissionsErrorDialogData:
                  _vm.integratedWithIncorrectPermissions,
              },
              on: {
                closed: function ($event) {
                  return _vm.startReintegration()
                },
              },
            }),
            _c("p", { staticClass: "medium-font font-25 color-primary" }, [
              _vm._v(
                "Success! you have integrated your ASA account with the following apps:"
              ),
            ]),
            _c(
              "div",
              { staticClass: "apps-list" },
              [
                _vm._l(_vm.appsList, function (app) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-start align-center px-4 py-1",
                      },
                      [
                        _c("v-avatar", { attrs: { size: "36px" } }, [
                          _c("img", {
                            attrs: { alt: "AppIcon", src: app.content.icon },
                          }),
                        ]),
                        _c("p", { staticClass: "mx-4 mb-0 color-primary" }, [
                          _vm._v(_vm._s(app.content.title)),
                        ]),
                      ],
                      1
                    ),
                  ]
                }),
                _vm.noAppsFound ? [_vm._m(0)] : _vm._e(),
              ],
              2
            ),
            !_vm.noAppsFound
              ? _c(
                  "p",
                  {
                    staticClass: "font-18 medium-font greybase--text mt-4 mb-2",
                  },
                  [
                    _vm._v(
                      "Not the list of apps you expected? reintegrate with a different account or "
                    ),
                    _c("a", [_vm._v("click here")]),
                    _vm._v(" for more help."),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex justify-end mt-4" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "color-primary",
                    attrs: { outlined: "" },
                    on: {
                      click: function ($event) {
                        return _vm.startReintegration()
                      },
                    },
                  },
                  [_vm._v("Reintegrate")]
                ),
                !_vm.integratedWithIncorrectPermissions
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-4",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.next()
                          },
                        },
                      },
                      [_vm._v("Continue")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "no-apps-message-wrapper d-flex align-center" },
      [
        _c(
          "p",
          {
            staticClass:
              "pa-8 flex-grow-1 text-center font-16 medium-font greybase--text",
          },
          [
            _vm._v(
              "No Apps Found! Either the integrated account doesn't have apps linked with Apple Store Connect account or we're unable to access them."
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }