var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setup-container" }, [
    _c(
      "div",
      { staticClass: "setup-steps py-4 px-12" },
      [
        _c(
          "p",
          { staticClass: "medium-font font-36 color-primary setup-title mb-0" },
          [_vm._v("Setup")]
        ),
        _c(
          "v-stepper",
          {
            model: {
              value: _vm.setupStepsModel,
              callback: function ($$v) {
                _vm.setupStepsModel = $$v
              },
              expression: "setupStepsModel",
            },
          },
          [
            _c(
              "v-stepper-header",
              [
                _c("v-stepper-step", {
                  attrs: { complete: _vm.setupStepsModel > 1, step: "1" },
                }),
                _c("v-divider"),
                _c("v-stepper-step", {
                  attrs: { complete: _vm.setupStepsModel > 2, step: "2" },
                }),
                _c("v-divider"),
                _c("v-stepper-step", { attrs: { step: "3" } }),
              ],
              1
            ),
            _c(
              "v-stepper-items",
              [
                _c(
                  "v-stepper-content",
                  { attrs: { step: "1" } },
                  [
                    _c("IntegrationStep", {
                      on: {
                        next: function ($event) {
                          return _vm.moveToSyncStep()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-stepper-content",
                  { attrs: { step: "2" } },
                  [
                    _c("SyncStep", {
                      on: {
                        next: function ($event) {
                          return _vm.moveToMMPStep()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-stepper-content",
                  { attrs: { step: "3" } },
                  [_c("MmpStep")],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }