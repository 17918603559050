




































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import AddUsers from './AddUsers/AddUsers.vue';
import IntegrationStep from './IntegrationStep/IntegrationStep.vue';
import SyncStep from './SyncStep/SyncStep.vue';
import MmpStep from './MmpStep/MmpStep.vue';
import { isEmpty } from 'lodash';
import { IntegrationService } from '@services/integration.service';
import { NavigationGuardNext, Route } from 'vue-router';
import { ALLOWED_INTEGRATION_ROLES } from 'src/utils/constants';

export default Vue.extend({
    name: 'Setup',
    components: {
        IntegrationStep,
        SyncStep,
        MmpStep,
        AddUsers
    },
    data() {
        return {
            setupStepsModel: 1
        };
    },
    props: {

    },
    methods: {
        moveToSyncStep() {
            this.setupStepsModel = 2;
        },
        moveToMMPStep() {
            this.setupStepsModel = 3;
        },
        _applyQueryAndReload(query) {
            this.$router.replace({ query });
            window.location.reload();
        },
        async checkASAIntegrationsState() {
            try {
                const integrationStateResponse = await IntegrationService.getASAIntegrationsState();

                if (!integrationStateResponse.length) return;

                const firstBadPermissionsIntegration = integrationStateResponse.find(int => !ALLOWED_INTEGRATION_ROLES.includes(int.roleName));

                if (firstBadPermissionsIntegration) return this._applyQueryAndReload({ integrated: 'true', id: firstBadPermissionsIntegration.id });

                const firstInvalidIntegration = integrationStateResponse.find(int => int.invalid);

                if (firstInvalidIntegration) return this._applyQueryAndReload({ invalid: 'true', id: firstBadPermissionsIntegration.id });

            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted() {
        if (this.$route.query.invalid) {
            this.moveToSyncStep();
        }

        if (!Object.keys(this.$route.query).length) {
            this.checkASAIntegrationsState();
        }
    }
});
