import { IOnboardDetails, ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

import { API_BASE_URL } from '@config';
import { HttpService } from '@core/services';
import { ONBOARD_ROUTES } from '@shared/config';

/**
 * @class OnboardService
 * @description service class for handling onboarding process.
 */
export class OnboardService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, ONBOARD_ROUTES.NoVbase);
  }
  
  /**
   * @function
   * @name save
   * @description service function for saving onboarding details
   * @param {string} securityPrincipleID
   * @param {any} onboardDetails
   * @returns {Promise<any>} response as a promise
   */
  public static async save(securityPrincipleID: string, onboardDetails: IOnboardDetails): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url, replaceParamsWithValues(ONBOARD_ROUTES.save, securityPrincipleID) ), onboardDetails);
    return response;
  }

  /**
   * @function
   * @name getAppsList
   * @description Get all the apps list.
   * @param {string} integrationID Integration ID
   * @returns { Promise<ISuccess> } Success Response or exception
   */
  public static async getAppsList(integrationID: string): Promise<ISuccess> {
    const url = joinUrl(this.url, replaceParamsWithValues(ONBOARD_ROUTES.getAppsList, integrationID));
    const response = await HttpService.get<ISuccess>(url);
    return response.data;
  }

  /**
   * @function
   * @name onboardFirst
   * @description Service function for handling the first time a user clicks on an email verification link.
   * @param {string} securityPrincipleID - The security principle ID.
   * @returns {Promise<ISuccess>} A promise that resolves to a response of type ISuccess.
   */
  public static async onboardFirst(securityPrincipleID: string): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(
      joinUrl(this.url, replaceParamsWithValues(ONBOARD_ROUTES.onboardFirst, securityPrincipleID)),
      { withCredentials: true }
    );
    return response;
  }

  /**
   * @function
   * @name getRoleName
   * @description Get the role name.
   * @param {string} integrationID Integration ID
   * @returns {Promise<ISuccess>} Success Response or exception
   */
  public static async getRoleName(integrationID: string): Promise<string> {
    const url = joinUrl(this.url, replaceParamsWithValues(ONBOARD_ROUTES.getRoleName, integrationID));
    const response = await HttpService.get<ISuccess>(url, { withCredentials: true });
    return response.data;
  }
}
