var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.syncFailed
      ? _c(
          "div",
          [
            _c("EmailMe", {
              attrs: { subscribeTo: _vm.emailOnSyncCompletion },
            }),
            _c("AddUsers", {
              attrs: { startAddingUsers: _vm.startAddingUsers },
              on: {
                closed: function ($event) {
                  return _vm.handleAddUsersClosed()
                },
              },
            }),
            _vm.syncProgress < 100
              ? _c("p", { staticClass: "medium-font font-25 color-primary" }, [
                  _vm._v("Success! Syncing in progress"),
                ])
              : _c("p", { staticClass: "medium-font font-25 color-primary" }, [
                  _vm._v("Success! Sync is completed"),
                ]),
            _c(
              "v-progress-circular",
              {
                attrs: {
                  rotate: "-90",
                  size: 64,
                  width: 8,
                  color: "primary",
                  value: _vm.syncProgress,
                  indeterminate: !_vm.syncProgress,
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.syncProgress) + "%")])]
            ),
            _c("div", { staticClass: "my-14" }, [
              _vm.syncProgress < 100
                ? _c(
                    "p",
                    { staticClass: "medium-font font-16 color-primary" },
                    [
                      _vm._v(
                        "ASA data is being imported! Please note the initial ASA integration can take several hours. Third party event data cannot be imported until after your ASA sync is complete. "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-space-between" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.emailOnSyncCompletion,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.subscribeToEmail()
                      },
                    },
                  },
                  [_vm._v("Email me")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addUsers()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v("Add Users")],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.next()
                      },
                    },
                  },
                  [_vm._v("Integrate your MMP")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-8" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "overview", query: { tour: "true" } },
                    },
                  },
                  [_vm._v("Continue to the application")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("p", { staticClass: "medium-font font-25 color-red-base" }, [
              _vm._v("Syncing has failed!"),
            ]),
            _c("p", { staticClass: "medium-font font-16 color-primary" }, [
              _vm._v(
                "Syncing has failed due to an irrecoverable error. Please integrate your Apple ID again."
              ),
            ]),
            _c(
              "v-btn",
              {
                staticClass: "mt-4",
                attrs: { color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.startAsaIntegration()
                  },
                },
              },
              [_c("strong", [_vm._v("Enter your apple ID")])]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }