















































import Vue from 'vue';
import IncorrectPermissions from '../IncorrectPermissions/IncorrectPermissions.vue';
import { IntegrationTypes } from '@shared/enums';
import { GET_OAUTH_URL } from 'src/utils/apollo-queries';
import { OnboardService } from '@services/onboard.service';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from '@core/services';
import { DELETE_INTEGRATION } from 'src/utils/apollo-mutations';
import { ALLOWED_INTEGRATION_ROLES } from 'src/utils/constants';

export default Vue.extend({
    name: 'IntegrationStep',
    components: {
        IncorrectPermissions
    },
    data() {
        return {
            integrated: false,
            integrationId: null,
            appsList: [],
            noAppsFound: false,
            integratedWithIncorrectPermissions: false
        };
    },
    props: {

    },
    methods: {
        async startReintegration() {
            this.fullScreenLoader = this.$loading.show();
            try {
                await this._deleteCurrentIntegration();
                await this.startAsaIntegration();
            } catch (error) {
                this.$notify.error('Failed to start reintegration.');
                console.log(error);
            }

            this.fullScreenLoader.hide();
        },
        async _deleteCurrentIntegration() {
            const deletionResponse = await this.$apollo.mutate({
                mutation: DELETE_INTEGRATION,
                variables: {
                    identifier: this.$route.query.id,
                    TeamID: decodeToken(LSS.token).TeamID,
                    type: IntegrationTypes.APPLE_SEARCH_ADS
                },
            });

            if (!deletionResponse.data?.deleteIntegration?.successMessage) throw deletionResponse;
        },
        async startAsaIntegration() {
            try {
                const result = await this.$apollo.query({
                    query: GET_OAUTH_URL,
                    variables: {
                        req: { type: IntegrationTypes.APPLE_SEARCH_ADS, referrer: this.$route.name, skipWaitingForIntegrationToDelete: true },
                    },
                });
                location.href = result.data.getOAuthState.url;
            } catch (error) {
                this.$notify.error(this.$t('successMsg.signinFailMsg'));
            }
        },
        async _getIntegratedRole(integrationId: string) {
            try {
                const roleName = await OnboardService.getRoleName(integrationId);
                if (!ALLOWED_INTEGRATION_ROLES.includes(roleName)) {
                    this.integratedWithIncorrectPermissions = true;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async _getIntegratedApps(integrationId: string) {
            this.fullscreenLoader = this.$loading.show();
            try {
                this.appsList = await OnboardService.getAppsList(integrationId);
                this.noAppsFound = !this.appsList.length;
            } catch (error) {
                console.log(error);
            }
            this.fullscreenLoader.hide();
        },
        next() {
            this.$root.$emit('setup:integrationstep:appslist', { appsList: this.appsList });
            this.$emit('next');
        },
        initializeState() {
            /**
             * A user will be asked to integrate again if their ASA account errors out.
             * It is likey and recommended for that user to just reintegrate the same account and update it.
             * In which case, we'll receive integrationUpdated query param.
             */
            if (this.$route.query.integrated || this.$route.query.integrationUpdated) {
                this.integrated = true;
                this.integrationId = this.$route.query.id;

                this._getIntegratedApps(this.integrationId);
                this._getIntegratedRole(this.integrationId);
            }
        }
    },
    created() {
        this.initializeState();
    }
});
