var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SuccessDialog", {
        attrs: { successMessageData: _vm.successMessageData },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "555" },
          model: {
            value: _vm.addUsersDialog,
            callback: function ($$v) {
              _vm.addUsersDialog = $$v
            },
            expression: "addUsersDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end px-6 pt-6" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "primary--text",
                        on: {
                          click: function ($event) {
                            return _vm.closeDialog()
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "px-10" },
                  [
                    _c(
                      "p",
                      { staticClass: "medium-font font-25 color-primary" },
                      [_c("strong", [_vm._v("Add Users")])]
                    ),
                    _c(
                      "p",
                      { staticClass: "medium-font font-18 color-primary" },
                      [
                        _vm._v(
                          "Enter the emails of people you want to invite, separated by commas. We'll send them an email with instructions to register. "
                        ),
                      ]
                    ),
                    _c(
                      "v-form",
                      {
                        model: {
                          value: _vm.formValid,
                          callback: function ($$v) {
                            _vm.formValid = $$v
                          },
                          expression: "formValid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mt-4",
                          attrs: {
                            rules: _vm.emailRules,
                            label: "Add Emails",
                            required: "",
                          },
                          model: {
                            value: _vm.emailsModel,
                            callback: function ($$v) {
                              _vm.emailsModel = $$v
                            },
                            expression: "emailsModel",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-card-actions", { staticClass: "justify-end" }, [
                _c(
                  "div",
                  { staticClass: "my-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "color-primary",
                        attrs: { text: "", outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.closeDialog()
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-4",
                        attrs: { disabled: !_vm.formValid, color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.sendInviteEmails()
                          },
                        },
                      },
                      [_vm._v(" Send ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }