import { render, staticRenderFns } from "./MmpStep.vue?vue&type=template&id=b0405572&scoped=true&"
import script from "./MmpStep.vue?vue&type=script&lang=ts&"
export * from "./MmpStep.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0405572",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VForm,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/sam-mono/sam-mono/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b0405572')) {
      api.createRecord('b0405572', component.options)
    } else {
      api.reload('b0405572', component.options)
    }
    module.hot.accept("./MmpStep.vue?vue&type=template&id=b0405572&scoped=true&", function () {
      api.rerender('b0405572', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/WelcomeOnboard/Setup/MmpStep/MmpStep.vue"
export default component.exports