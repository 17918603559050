




































import Vue from 'vue';
import SuccessDialog from '../SuccessDialog/SuccessDialog.vue';
import { ADD_MULTIPLE_MEMBERS } from 'src/utils/apollo-mutations';

export default Vue.extend({
    name: 'AddUsers',
    components: {
        SuccessDialog
    },
    data() {
        return {
            formValid: false,
            addUsersDialog: false,
            emailsModel: '',
            successMessageData: {},
            emailRules: [
                (v) => !!v || 'This field is required',
                (v) =>
                    !v.split(',').filter(_v => !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        _v
                    )).length || 'Must be a comma separated list of valid emails',
                (v) => !/\s/.test(v) || 'Cannot contain spaces',
            ],
        };
    },
    props: {
        startAddingUsers: {
            type: Boolean
        }
    },
    watch: {
        startAddingUsers(value) {
            if (value) {
                this.addUsersDialog = value;
            }
        }
    },
    methods: {
        closeDialog() {
            this.addUsersDialog = false;
            this.resetForm();
            this.$emit('closed');
        },
        resetForm() {
            this.emailsModel = '';
        },
        async sendInviteEmails() {
            this.fullScreenLoader = this.$loading.show();
            try {
                const inviteResponse = await this.$apollo.mutate({
                    mutation: ADD_MULTIPLE_MEMBERS,
                    variables: { addMembersEmails: this.emailsModel.split(',') }
                });

                this._handleInviteResponse(inviteResponse);
            } catch (error) {
                this.$notify.error('Failed to send invite emails.');
                console.error(error);
            }

            this.fullScreenLoader.hide();
        },
        _handleInviteResponse(response) {
            if (response.data.addMultipleTeamMembers.code != 0) throw response;

            this.closeDialog();

            this.successMessageData = {
                title: 'Success!',
                message: 'An invitation has been sent to the new emails to join or switch to this company on Search Ads Maven.'
            };
        }
    },
});
