




























// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
    name: 'IncorrectPermissions',
    data() {
        return {
            permissionsErrorDialog: false
        };
    },
    props: {
        permissionsErrorDialogData: {
            type: Boolean
        }
    },
    watch: {
        permissionsErrorDialogData(value) {
            if (value) {
                this.permissionsErrorDialog = true;
            }
        }
    },
    methods: {
        close() {
            this.permissionsErrorDialog = false;
            this.$emit('closed');
        }
    },
});
