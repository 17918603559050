var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "650" },
      model: {
        value: _vm.permissionsErrorDialog,
        callback: function ($$v) {
          _vm.permissionsErrorDialog = $$v
        },
        expression: "permissionsErrorDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-end px-6 pt-6" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "primary--text",
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("mdi-close")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "px-10" }, [
              _c("p", { staticClass: "medium-font font-25 color-red-base" }, [
                _c("strong", [_vm._v("Incorrect Permissions!")]),
              ]),
              _c(
                "p",
                { staticClass: "mt-5 medium-font font-18 color-primary" },
                [
                  _vm._v(
                    "The integration does not have the correct permissions to allow SAM to function normally. Please reintegrate with either of the following permissions:"
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticClass:
                    "mt-5 account-permissions-1 medium-font font-18 color-primary",
                },
                [_vm._v("1. Account Read & Write ")]
              ),
              _c(
                "p",
                { staticClass: "mt-1 medium-font font-18 color-primary" },
                [_vm._v("2. Limited Access: Read & Write")]
              ),
            ]),
          ]),
          _c("v-card-actions", { staticClass: "justify-end" }, [
            _c(
              "div",
              { staticClass: "mb-6 mt-7" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-6",
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v(" Reintegrate ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }