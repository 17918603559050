
















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
    name: 'SuccessDialog',
    data() {
        return {
            successDialog: false
        };
    },
    props: {
        successMessageData: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    watch: {
        successMessageData(value) {
            if(value) {
                this.successDialog = true;
            }
        }
    },
    methods: {
        close() {
            this.successDialog = false;
            this.$emit('closed');
        }
    },
});
