var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "900" },
          model: {
            value: _vm.integrationGuideDialog,
            callback: function ($$v) {
              _vm.integrationGuideDialog = $$v
            },
            expression: "integrationGuideDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end px-6 pt-6" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "primary--text",
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "px-10" },
                  [
                    _c(
                      "p",
                      { staticClass: "medium-font font-25 color-primary" },
                      [
                        _c("strong", [
                          _vm._v(
                            "Integrate With " +
                              _vm._s(_vm.mmpIntegrationData.mmpId)
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "p",
                      { staticClass: "mt-4 medium-font font-18 color-primary" },
                      [_vm._v("Follow these integration steps")]
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "8" } }, [
                          _c(
                            "p",
                            {
                              staticClass: "regular-font font-15 color-primary",
                            },
                            [
                              _vm.mmpIntegrationData.mmpId ===
                              _vm.MMP.KOCHAVA_ID
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("integration.kochavaStep1")
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep2")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep3")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep4")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep5")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep6")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep7")
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("integration.kochavaStep8")
                                      ) + " "
                                    ),
                                    _c("br"),
                                  ]
                                : _vm._e(),
                              _vm.mmpIntegrationData.mmpId === _vm.MMP.BRANCH_ID
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("integration.branchStep")
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.mmpIntegrationData.mmpId === _vm.MMP.CUSTOM_ID
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("integration.customStep")
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "partner-app d-flex align-center justify-center pt-3 pb-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "partner-integrate d-flex align-center px-4 flex-column",
                                },
                                [
                                  _vm.mmpIntegrationData.mmpId ===
                                  _vm.MMP.KOCHAVA_ID
                                    ? _c("v-img", {
                                        attrs: {
                                          src: require("../../../../../assets/images/kochava.png"),
                                          width: "50px",
                                          height: "50px",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.mmpIntegrationData.mmpId ===
                                  _vm.MMP.BRANCH_ID
                                    ? _c("v-img", {
                                        attrs: {
                                          src: require("../../../../../assets/images/branch_logo.png"),
                                          width: "50px",
                                          height: "50px",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.mmpIntegrationData.mmpId ===
                                  _vm.MMP.CUSTOM_ID
                                    ? _c("v-img", {
                                        attrs: {
                                          src: require("../../../../../assets/images/custom.png"),
                                          width: "50px",
                                          height: "50px",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("h6", [
                                    _vm._v(
                                      _vm._s(_vm.mmpIntegrationData.mmpId)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-icon", { staticClass: " px-4" }, [
                                _vm._v("mdi-arrow-right-thick"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "integrate-app d-flex align-center px-4 flex-column ",
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src:
                                        _vm.mmpIntegrationData.app.content.icon,
                                      height: "50px",
                                      width: "50px",
                                    },
                                  }),
                                  _c("h6", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mmpIntegrationData.app.content.title
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "my-4 copy-only-text" },
                            [
                              _c("label", { staticClass: "sticky-label" }, [
                                _vm._v("API Key"),
                              ]),
                              _c("v-text-field", {
                                staticClass: "copy-only-text__input",
                                attrs: {
                                  "append-icon":
                                    "mdi-checkbox-multiple-blank-outline",
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  readonly: "",
                                  value: _vm.mmpIntegrationData.apiKey,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    return _vm.copyToClipboard(
                                      _vm.mmpIntegrationData.apiKey
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "my-4 copy-only-text" },
                            [
                              _c("label", { staticClass: "sticky-label" }, [
                                _vm._v("App Store ID"),
                              ]),
                              _c("v-text-field", {
                                staticClass: "copy-only-text__input",
                                attrs: {
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  readonly: "",
                                  "append-icon":
                                    "mdi-checkbox-multiple-blank-outline",
                                  value: _vm.mmpIntegrationData.app.content.id,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    return _vm.copyToClipboard(
                                      _vm.mmpIntegrationData.app.content.id
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _vm.mmpIntegrationData.appEvents.length
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "font-15 regular-font color-primary",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.mmpIntegrationData.appEvents.length
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.$t("integration.distinctEventType")
                                      )
                                  ),
                                ]
                              )
                            : _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("integration.noDistinctEventType")
                                    )
                                ),
                              ]),
                          _c(
                            "span",
                            {
                              staticClass: "medium-font font-18 color-primary",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("integration.dEventType")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "events-list-wrapper my-2" },
                            _vm._l(
                              _vm.mmpIntegrationData.appEvents,
                              function (event) {
                                return _c(
                                  "p",
                                  {
                                    staticClass:
                                      "my-0 regular-font font-15 color-primary",
                                  },
                                  [_vm._v(_vm._s(event.name))]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-card-actions", { staticClass: "justify-end" }, [
                _c(
                  "div",
                  { staticClass: "mb-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-6",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [_vm._v(" Finish ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }