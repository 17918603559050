










































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import AddUsers from '../AddUsers/AddUsers.vue';
import EmailMe from '../EmailMe/EmailMe.vue';
import { subscribe } from 'graphql';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from '@core/services';
import { v4 as uuidv4 } from 'uuid';
import { GET_OAUTH_URL } from 'src/utils/apollo-queries';
import { IntegrationTypes } from '@shared/enums';

export default Vue.extend({
    name: 'SyncStep',
    components: {
        EmailMe,
        AddUsers
    },
    data() {
        return {
            syncFailed: false,
            emailOnSyncCompletion: false,
            startAddingUsers: false,
            syncProgress: 0
        };
    },
    props: {

    },
    methods: {
        async startAsaIntegration() {
            try {
                const result = await this.$apollo.query({
                    query: GET_OAUTH_URL,
                    variables: {
                        req: { type: IntegrationTypes.APPLE_SEARCH_ADS, referrer: this.$route.name },
                    },
                });
                location.href = result.data.getOAuthState.url;
            } catch (error) {
                this.$notify.error(this.$t('successMsg.signinFailMsg'));
            }
        },
        subscribeToEmail() {
            this.emailOnSyncCompletion = true;
        },
        addUsers() {
            this.startAddingUsers = true;
        },
        handleAddUsersClosed() {
            this.startAddingUsers = false;
        },
        next() {
            this.$emit('next');
        },
        /** Uses existing syncprogress machanism */
        subscribeToSyncEvents() {
            Vue.prototype.$sckt.on('syncprogress:event', async (progressEvent) => {
                if (progressEvent.error) {
                    if (progressEvent.errCode === 'INVALID_INTEGRATION') this.syncFailed = true;
                } else {
                    this.syncProgress = progressEvent.progress;
                }
            });
        },
        checkSyncEvents() {
            const user = decodeToken(LSS.token);
            Vue.prototype.$sckt.emit('syncprogress:check', {
                teamId: user.TeamID,
                CorrID: uuidv4(),
                user
            });
        }
    },
    mounted() {
        // route params are passed as string
        this.syncFailed = this.$route.query.invalid == 'true';
        this.checkSyncEvents();
        this.subscribeToSyncEvents();
    }
});
