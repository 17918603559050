












































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { MMP } from 'src/utils/enums';

export default Vue.extend({
    props: {
        mmpIntegrationData: {
            type: Object,
            required: false
        }
    },
    components: {},
    data() {
        return {
            integrationGuideDialog: false,
            MMP: MMP
        };
    },
    watch: {
        mmpIntegrationData: {
            handler(data) {
                if (data && data.integrationGuideDialog) this.integrationGuideDialog = true;
            },
            deep: true
        }
    },
    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            this.$notify.success("Copied");
        },
        close() {
            this.integrationGuideDialog = false;
            this.$emit('closed');
        }
    },
});
