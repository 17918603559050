




// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import SuccessDialog from '../SuccessDialog/SuccessDialog.vue';
import { UPDATE_NOTIFY_SETTING } from 'src/utils/apollo-mutations';
import { variables } from 'blockly/blocks';

export default Vue.extend({
    name: 'EmailMe',
    components: {
        SuccessDialog
    },
    data() {
        return {
            successMessageData: {}
        };
    },
    props: {
        subscribeTo: {
            type: Boolean
        }
    },
    watch: {
        subscribeTo(value) {
            if (value) {
                this.subscribe(value);
            }
        }
    },
    methods: {
        async subscribe(notifyEnabled: boolean) {
            this.fullScreenLoader = this.$loading.show();
            try {
                const response = await this.$apollo.query({
                    query: UPDATE_NOTIFY_SETTING,
                    variables: { notifyEnabled }
                });

                this._handleNotifySettingResponse(response);
            } catch (error) {
                this.$notify.error('Failed to subscribe to sync email notification.');
                console.error(error);
            }

            this.fullScreenLoader.hide();
        },
        _handleNotifySettingResponse(response) {
            if (response.data.updateNotifySetting.code != 0) throw response;

            this.successMessageData = {
                title: 'Success!',
                message: 'You\’ll receive an email when sync is completed.'
            };
        }
    },
});
