var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "555" },
      model: {
        value: _vm.successDialog,
        callback: function ($$v) {
          _vm.successDialog = $$v
        },
        expression: "successDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-end px-6 pt-6" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "primary--text",
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("mdi-close")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "px-10" }, [
              _c("p", { staticClass: "medium-font font-25 color-primary" }, [
                _c("strong", [_vm._v(_vm._s(_vm.successMessageData.title))]),
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "mt-10 mb-16 pb-16 medium-font font-18 color-primary",
                },
                [_vm._v(_vm._s(_vm.successMessageData.message))]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }